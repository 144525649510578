import { createContext, useContext, useMemo, useState } from "react";

type Permission =
  | "user:read:all"
  | "user:write:all"
  | "event:read:all"
  | "event:write:all"
  | "config:read:all"
  | "config:write:all"
  | "payment:read:all"
  | "payment:write:all";

type AvailableRoles = "BACKOFFICE" | "BACKOFFICE_OPS" | "BACKOFFICE_FINANCIAL";

interface Role {
  name: AvailableRoles;
  permissions: Permission[];
}

const roles: Role[] = [
  {
    name: "BACKOFFICE",
    permissions: [
      "user:read:all",
      "user:write:all",
      "event:read:all",
      "event:write:all",
      "config:read:all",
      "config:write:all",
      "payment:read:all",
      "payment:write:all",
    ],
  },
  {
    name: "BACKOFFICE_OPS",
    permissions: [
      "user:read:all",
      "user:write:all",
      "event:read:all",
      "event:write:all",
      "config:read:all",
      "config:write:all",
    ],
  },
  {
    name: "BACKOFFICE_FINANCIAL",
    permissions: ["user:read:all", "event:read:all", "payment:read:all", "payment:write:all"],
  },
];

type PermissionsProviderState = {
  permissions: string[];
  userRole: AvailableRoles | null;
  setPermissions: (permissions: string[]) => void;
};

const initialState: PermissionsProviderState = {
  permissions: [],
  userRole: null,
  setPermissions: () => null,
};

const PermissionsProviderContext = createContext<PermissionsProviderState>(initialState);

const PermissionsProvider = ({ children }: { children: React.ReactNode }) => {
  const [permissions, setPermissions] = useState<string[]>([]);

  function getRoleByPermissions(permissions: Permission[]): AvailableRoles | null {
    for (const role of roles) {
      if (
        role.permissions.length === permissions.length &&
        role.permissions.every((permission) => permissions.includes(permission))
      ) {
        return role.name;
      }
    }
    return null;
  }

  const userRole = useMemo(() => {
    const userRole = getRoleByPermissions(permissions as Permission[]);
    return userRole;
  }, [permissions]);

  return (
    <PermissionsProviderContext.Provider value={{ permissions, userRole, setPermissions }}>
      {children}
    </PermissionsProviderContext.Provider>
  );
};

/**
 * Permissions provider hook.
 * @returns - permissions and setPermissions function.
 */
const usePermissions = () => {
  const context = useContext(PermissionsProviderContext);

  if (context === undefined)
    throw new Error("usePermissions must be used within a PermissionsProvider");

  return context;
};

export { PermissionsProviderContext, PermissionsProvider, usePermissions, type AvailableRoles };
